export const environment = {
  production: false,
  // apiUrl: 'https://online.blixentours.com/mydhruvapi',
  apiUrl: 'https://online.blixentours.com/mydhruvapitest',
  apiUrlPdf: 'https://myblixen.isgesolutions.com/mydhruvpdf',
  dashboardDetails: 'api/v2/dashboard/dashboarddetails',
  login: 'api/v2/account/login',  
  clientTrips: 'api/v2/dashboard/clienttrips',
  headerDetails: 'api/v2/dashboard/headerdetails',
  footerDetails: 'api/v2/dashboard/footerdetails',
  googleMapsApiKey: 'AIzaSyBrh-RJj1pi1v4g7PeyclvNJKQKWVAAYhc',
  itinerarydetails: 'api/v2/itinerary/itinerarydetails',
  accomodationdetails: 'api/v2/itinerary/accomodationdetails',
  pricedetails: 'api/v2/itinerary/pricedetails',
  flightdetails: 'api/v2/itinerary/flightdetails',
  essentialinfo: 'api/v2/itinerary/essentialdetails',
  complaints: 'api/v2/itinerary/complaintdetails',
  travelAdvice: 'api/v2/itinerary/traveladvicedetails',
  menulist: 'api/v2/itinerary/menulist',
  mapdetails: 'api/v2/itinerary/mapdetails',
  forgotpassword: 'api/v2/account',
  clienttrips: 'api/v2/dashboard/clienttrips',
  menuList: 'api/v2/itinerary/menulist',
  itineraryDetails: 'api/v2/itinerary/itinerarydetails',
  travelExpertDetails: 'api/v2/itinerary/travelexpertdetails',
  correspondenceDetails: 'api/v2/itinerary/correspondencedetails',
  profilechangepassword: 'api/v2/account/changepassword',
  resetpassword: 'api/v2/account/resetpassword',
  authToken: 'api/v2/account/token',
  dictionary: 'api/v2/dashboard/translationwords',
  termsAndCondition: 'api/v2/itinerary/termsandconditions',
  travelOverview: 'api/v2/itinerary/overviewmenulist',
  travelAdviceMenu: 'api/v2/itinerary/traveladvicemenulist',
  documentDetails: 'api/v2/itinerary/documentdetails',
  register: 'api/v2/account/register',
  pdfApiUrl: 'https://myblixen.isgesolutions.com/mydhruvpdf',
  // pdfApiUrl: 'http://localhost:3000',  
  downloadPdf: 'generate-pdf',
  documentDetailsDoc: 'https://dhruv.blixentours.com/dhruvapi/api/file/GetproductDocLinkWithSeqNo/51',
  KENDO_UI_LICENSE: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjM2MTQ3NTE5fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTYzNjE0NzUxOX0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjM2MTQ3NTE5fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjM2MTQ3NTE5fV0sImludGVncml0eSI6ImIrcnNPbVlqc2ExNHJaSFFSUXBUQ1poRUpHST0iLCJsaWNlbnNlSG9sZGVyIjoicmFqZWV2QGlzZ2Vzb2x1dGlvbnMuY29tIiwiaWF0IjoxNjcwMjYzMTczLCJhdWQiOiJyYWplZXZAaXNnZXNvbHV0aW9ucy5jb20iLCJ1c2VySWQiOiJmZmMwYWJiZC1kYzdiLTQwZWUtYmE2OS00MDMzNDYwYzBmNzMifQ.AWa1wTffUtHOQgaru6dnveg3W8FzLe8yReRjWngVt7HbQLuEzOrYDMgvmtLrEIIhRhmCtIT33td95kfFEbddansPWKI4lTN3_yNajJjcBfZ9IMRzrKRlgBErSnLsYNuZ69V1CUSEv-eXLa7H37qKH3KcPB9LtqevYE5uX7UKPzdPmjJint_Gccf3Wr2PPsp2K8eX3_i2YFFAxTBNO0PAmJOSWffxWT5H7U-efYU_aIxvDM_67jwICYlfAYQ2faobO1hfIubR6_6LEDYK_PBdYcAAWiq0C4Dkc1x7clVXjUJJtu_MJsm4otVa7vmuNKkU4ftyBXFaoIXjRTGMtPEn3A'
};
